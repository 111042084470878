<template>
    <section class="partnership my-5">
        <h2 class="partnership__title mt-3">{{ getStrategicProjectsPageInfo('title') }}</h2>

<!--        <p class="partnership__text col-md-7">-->
<!--            Университет ИТМО является ведущим вузом России-->
<!--            в сфере науки и образования. Научные достижения Университета ИТМО получили широкое признание, что-->
<!--            подтверждается научными проектами и разработками, широким международным научным сотрудничеством, количеством-->
<!--            научных публикаций, постоянно расширяющимся кругом партнеров. Для расширения партнерской сети в университете-->
<!--            создан Центр научного бизнес партнерства, отвечающий за комплексное взаимодействие-->
<!--            с российскими и международными компаниями-->
<!--            на принципах открытого сетевого взаимодействия и их вовлечения в глобальную экосистему университета.-->
<!--        </p>-->

        <div class="d-flex justify-content-center w-100">
            <div style="width: 1448px;">
                <div class="partnership_cards d-flex justify-content-center flex-column flex-xl-row mt-5">
                    <div class="d-flex flex-column col-xl-5" style="max-width: 1448px">
                        <div class="h-100">
                            <div class="d-flex flex-column flex-md-row h-100">
                                <div
                                    class="partnership_card strategic-projects__partnership-agreement partnership_card--primary col-12 col-md-5 p-3">
                                    <div class="partnership-agreement__text">Соглашение о партнeрстве</div>
                                    <div class="card-agreement__text mt-2 d-flex">
                                        <div class="partnership-agreement__download me-2">
                                            <a target="_blank" :href="getAgreementFile">Скачать соглашение</a>
                                        </div>
                                        <div><img src="../../assets/views/partnership/partnership-arrow.svg" alt="">
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="partnership_card d-flex flex-column partnership_card--light col-12 col-md-7 mt-3 mt-md-0 ms-md-3"
                                    @click="goToPage('Highpark')"
                                >
                                    <div class="d-flex partnership-scientific">
                                        <div class="partnership-scientific__text ms-4 mt-3">
                                            <router-link :to="{ name: 'Highpark' }">
                                                ИТМО Хайпарк
                                            </router-link>
                                        </div>

                                        <img
                                            class="d-block mt-auto partnership-educational__img ms-auto"
                                            src="../../assets/views/partnership/strategic-partnership1.svg"
                                            alt=""
                                        >
                                    </div>

                                    <img
                                        class="d-inline-block technology-transfer__expertise ms-auto"
                                        src="../../assets/views/partnership/strategic-partnership2.svg"
                                        alt=""
                                        width="220"
                                        height="116"
                                    >
                                </div>
                            </div>
                        </div>

                        <div
                            class="partnership_card partnership_card--light d-flex flex-column mt-3"
                            @click="goToPage('NCCR')"
                        >
                            <div class="partnership-innovation__text ms-4 mt-3">
                                <router-link :to="{ name: 'NCCR' }">
                                    Национальный центр когнитивных разработок
                                </router-link>
                            </div>
                            <img
                                class="d-block mt-auto technology-transfer__expertise ms-auto"
                                src="../../assets/views/partnership/innovation-partnership.svg"
                                alt=""
                                width="346"
                                height="120"
                            >
                        </div>
                    </div>

                    <div
                        class="partnership_card strategic-project__rc-card partnership_card--light d-flex flex-column col-xl-4 ms-xl-4 mt-3 mt-xl-0"
                        @click="goToPage('ResearchCenter')"
                    >
                        <div class="partnership-educational__text ms-4 my-3">
                            <router-link :to="{ name: 'ResearchCenter' }">
                                Исследовательский центр «Сильный искусственный интеллект в промышленности»
                            </router-link>
                        </div>
                        <img
                            class="d-block mt-auto strategic-project__rc"
                            src="../../assets/views/partnership/educational-partnership1.svg"
                            alt=""
                            width="409"
                            height="256"
                        >
                    </div>

                    <div class="d-flex flex-column col-xl-3 ms-xl-4 mt-3 mt-xl-0">
                        <div
                            class="partnership_card partnership_card--light d-flex flex-column"
                            @click="goToPage('NCCI')"
                        >
                            <div class="partnership-innovation__text ms-4 mt-3">
                                <router-link :to="{ name: 'NCCI' }">
                                    Национальный центр квантового интернета
                                </router-link>
                            </div>
                            <img
                                class="d-block mt-auto ms-auto strategic-project__ncci-img technology-transfer__expertise"
                                src="../../assets/views/partnership/innovation-partnership.svg"
                                alt=""
                                width="346"
                                height="120"
                            >
                        </div>

                        <div class="h-100 mt-3">
                            <div class="d-flex h-100">
                                <div
                                    class="partnership_card d-flex flex-column partnership_card--light w-100"
                                    @click="goToPage('Fund')"
                                >
                                    <div class="d-flex partnership-scientific">
                                        <div class="partnership-scientific__text ms-4 mt-3">
                                            <router-link :to="{ name: 'Fund' }">
                                                Эндаумент-фонд Университета ИТМО
                                            </router-link>
                                        </div>

                                        <img
                                            class="d-block mt-auto partnership-educational__img ms-auto"
                                            src="../../assets/views/partnership/strategic-partnership1.svg"
                                            alt=""
                                        >
                                    </div>

                                    <img
                                        class="d-inline-block ms-auto strategic-project__fund-img technology-transfer__expertise"
                                        src="../../assets/views/partnership/strategic-partnership2.svg"
                                        alt=""
                                        width="333"
                                        height="124"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";

export default {
    name: "StrategicProjects",

    setup() {
        const store = useStore()

        store.dispatch('widgets/getPartnershipAgreementWidgets')

        const partnershipAgreementWidgets = computed(() => store.getters['widgets/partnershipAgreementWidgets'])

        const getAgreementFile = computed(() => {
            if (partnershipAgreementWidgets.value) {
                return `/${partnershipAgreementWidgets.value.agreement_file.split('/').slice(3).join('/')}`
            }

            return ''
        })

        const { isMenuVisible, toggleMenu, goToPage } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getStrategicProjectsPage')
        const strategicProjectsPage = computed(() => store.getters['pages/strategicProjectsPage'])

        const getStrategicProjectsPageInfo = (key) => {
            if (strategicProjectsPage.value) {
                return strategicProjectsPage.value[key]
            }

            return ''
        }

        return {
            getAgreementFile,
            goToPage,
            getStrategicProjectsPageInfo
        }
    }
}
</script>
